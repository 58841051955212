.spark {
    background-color: #DE4A00;
    font-family: 'Helvetica', sans-serif;
    visibility: hidden;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    box-shadow: 0 0 2px #AB000B;
}

#bubble-canvas {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
}