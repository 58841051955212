.work {
    position: relative;

    .work-item {
        margin-bottom: 24px;

        img {
            @extend .img-border;
        }
    }


    .squirrel-wrap {
        margin-right: auto;
        margin-left: auto;
        display: inline-block;
        position: relative;
        margin-top: 84px;

        p {
            position: absolute;
            top: 19px;
            right: 0;
        }
    }

    .exit {
        display: none;
        text-align: right;
    }

    
    @media only screen and (min-width: $md) {
        h3 {
            min-height: 70px;
        }

        .work-item {

            &.expanded {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 999;
                background-color: $text-background;
                padding-top: 48px;
                max-width: 100%;
        
                .exit {
                    display: block;
                }
            }
        }

    }

}